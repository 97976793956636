<template>
  <section class="contact-section" id="contact">
    <div class="side-text">
      <p>{{ $t('contact') }}</p>
    </div>

    <div class="contact-center">
      <div class="contact-content">
        <!--

        <p>{{ $t('follow_us') }}</p>

        <div class="social-icons">
          <a href="https://www.instagram.com" target="_blank">
            <i class="pi pi-instagram"></i>
          </a>
          <a href="https://www.facebook.com" target="_blank">
            <i class="pi pi-facebook"></i>
          </a>
        </div>

        -->


        <!-- Text pro media a dotazy -->
        <p>{{ $t('media_contact') }}</p>

        <!-- Email -->
        <a href="mailto:hello@masterviolinmakermovie.com" class="email-link">hello@masterviolinmakermovie.com</a>
      </div>
    </div>


  </section>
</template>

<script>
export default {
  name: "ContactComponent",
};
</script>

<style scoped>
.contact-section {
  position: relative;
  min-height: 400px;
  background-color: #06181a;
  color: white;
  padding: 2rem;
}

.contact-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    font-weight: bold;
  }
}

.social-icons {
  display: flex;
  gap: 2rem;
  font-size: 2rem;
  margin: 1rem 0;
}

.social-icons a {
  color: #236267;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ffffff;
}

.email-link {
  color: #236267;
  margin-top: 1rem;
  font-size: 1.2rem;
  text-decoration: none;
}

.email-link:hover {
  color: #ffffff;
}

@media (max-width: 768px) {

  .social-icons {
    font-size: 1.5rem;
  }

  .email-link {
    font-size: 1rem;
  }
}
</style>
