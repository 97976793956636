<template>
  <section class="crew-section" id="crew">
    <div class="side-text">
      <p>{{ $t('crew') }}</p>
    </div>

    <div class="crew-list" data-aos="fade" data-aos-duration="3000">
      <div class="crew-item" v-for="(member, index) in crewMembers" :key="index">
        <div class="role"><p>{{ member.role }}</p></div>
        <div class="dotted-line"></div>
        <div class="name"><p>{{ member.name }}</p></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CrewComponent",
  data() {
    return {
      crewMembers: [],
    };
  },
  created() {
    this.crewMembers = [
      { role: this.$t('direction_and_camera'), name: "Miroslav Medal" },
      { role: this.$t('dramaturgy'), name: "Ondřej Smékal" },
      { role: this.$t('cut'), name: "Jan Bartoš" },
      { role: this.$t('sound'), name: "Lukáš Lukáš" },
      { role: this.$t('grading'), name: "Luděk Lazarczyk" },
      { role: this.$t('second_camera'), name: "Matěj Smékal" },
    ];
  },
};
</script>

<style scoped>
.crew-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: white;
  height: auto;
  position: relative;
}

.crew-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;
}

@media (max-width: 768px)  {
  .crew-list {
    width: 90%;
  }
}

.crew-item {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin-bottom: 1.5rem;
}

.role {
  width: 40%;
  text-align: left;
}

.dotted-line {
  flex-grow: 1;
  height: 1px;
  margin: 0 1rem;
  border-bottom: 2px dashed #ffffff;
}

.name {
  text-align: right;
  width: 40%;
}

@media (max-width: 768px) {

  .role, .name {
    font-size: 1.2rem;
  }

  .crew-item {
    margin-bottom: 1rem;
  }

  .crew-list {
    align-items: center;
  }
}
</style>
