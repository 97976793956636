<template>
  <section class="about-film" id="about-film">
    <!-- Text otočený na levé straně -->
    <div class="side-text">
      <p>{{ $t('about') }}</p>
    </div>

    <!-- Nadpis a popis sekce -->
    <div class="about-text" data-aos="fade-up" data-aos-duration="1500">
      <h1>{{ $t('title') }}</h1>
      <p class="desc-film">{{ $t('about_film_headline') }}</p>
      <p class="description">
        {{ $t('about_film_text') }}
      </p>
    </div>

    <!-- Galerie houslí -->
    <section class="violin-gallery" data-aos="fade-right" data-aos-duration="1500">
      <div class="violin" v-for="(violin, index) in violins" :key="index">
        <img :src="violin.src" :class="['violin-image', `violin-${index}`]" :alt="`Violin ${index + 1}`" />
      </div>
    </section>
  </section>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'AboutFilmComponent',
  data() {
    return {
      violins: [
        { src: require('@/assets/img/2scan_housle.png') },
        { src: require('@/assets/img/3scan_housle.png') },
        { src: require('@/assets/img/1scan_housle.png') },
        { src: require('@/assets/img/4scan_housle.png') },
      ],
    };
  },
  mounted() {
    AOS.init(); // Inicializace AOS při mountu komponenty
  },
};
</script>

<style scoped>
.about-film {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  color: white;
  text-align: justify;
}

.about-text {
  max-width: 800px;
  margin-bottom: 3rem;
}

.desc-film {
  text-align: center;
}

.about-text h1 {
  font-size: 3rem;
  margin-bottom: 3rem;
  text-align: center;
}

.about-text p {
  font-size: 1.2rem;
  opacity: 0.8;
}

.about-text .description {
  margin-top: 3rem;
  font-size: 1.1rem;
}

/* Galerie houslí */
.violin-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}



.violin-image {
  width: 250px;
  height: auto;
  opacity: 0.3;
  transition: opacity 0.5s ease-in-out;
  animation: fadeInOut 8s infinite;
}

@media (max-width: 768px) {
  .violin-image {
    display: none;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.3;
  }
  25% {
    opacity: 1;
  }
}

.violin-0 {
  animation-delay: 0s;
}

.violin-1 {
  animation-delay: 2s;
}

.violin-2 {
  animation-delay: 4s;
}

.violin-3 {
  animation-delay: 6s;
}
</style>
