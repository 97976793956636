<template>
  <div id="app">
    <LoaderComponent v-if="loading" />
    <div v-else>
      <VideoHeaderComponent />
      <AboutFilmComponent />
      <TrailerComponent />
      <CastComponent />
      <ScanViolinComponent />
      <DirectorComponent />
      <CrewComponent />
      <ContactComponent />
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import VideoHeaderComponent from './components/VideoHeaderComponent.vue';
import AboutFilmComponent from './components/AboutFilmComponent.vue';
import TrailerComponent from './components/TrailerComponent.vue';
import CastComponent from './components/CastComponent.vue';
import ScanViolinComponent from './components/ScanViolinComponent.vue';
import DirectorComponent from './components/DirectorComponent.vue';
import CrewComponent from './components/CrewComponent.vue';
import ContactComponent from "@/components/ContactComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
  name: 'App',
  components: {
    VideoHeaderComponent,
    AboutFilmComponent,
    TrailerComponent,
    CastComponent,
    ScanViolinComponent,
    DirectorComponent,
    CrewComponent,
    ContactComponent,
    FooterComponent,
    LoaderComponent,

  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    // Simulace delšího načítání s umělým zpožděním
    window.addEventListener("load", () => {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    });
  },
};
</script>

