import { createApp } from 'vue';
import App from './App.vue';
import './assets/styles/main.scss';
import { createI18n } from 'vue-i18n';
import messages_cs from './locales/cs.json';
import messages_en from './locales/en.json';
import 'primeicons/primeicons.css';

const savedLanguage = localStorage.getItem('language') || 'cs';

const i18n = createI18n({
    locale: savedLanguage,
    fallbackLocale: 'en',
    messages: {
        cs: messages_cs,
        en: messages_en
    },
});


const app = createApp(App);

// Použití i18n
app.use(i18n);
app.mount('#app');



