<template>
  <footer class="footer-section">
    <div class="logos">
      <img :src="require('@/assets/img/filharmonie_logo.png')" alt="Česká filharmonie" />
      <img :src="require('@/assets/img/pribram_logo.png')" alt="Příbram" />
      <img :src="require('@/assets/img/comforia_logo.png')" alt="Comforia" />
      <img :src="require('@/assets/img/slu_logo.png')" alt="Slezská univerzita" />
      <img :src="require('@/assets/img/mmt_logo.png')" alt="Multimediální techniky" />
    </div>

    <div class="footer-bottom">
      <p class="copyright">© 2024 {{ $t('title') }}</p>
     <div class="dominikmedal_dev">
       <p>Powered by</p>
       <a href="https://dominikmedal.cz/" target="_blank"><img :src="require('@/assets/img/logo_dominikmedal.png')" alt="DM" class="dm-logo" /></a>
     </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem 0;
  background-color: #06181a;
  color: white;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.logos img {
  height: 150px;
  object-fit: contain;
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.copyright {
  font-size: 0.9rem;
  color: #b0c7d1;
}

.dominikmedal_dev {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.dominikmedal_dev p {
  margin: 0;
  font-size: 0.9rem;
  color: #b0c7d1;
}

.dm-logo {
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .logos img {
    height: 60px;
  }

  .dm-logo {
    font-size: 1.2rem;
  }
}

</style>
