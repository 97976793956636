<template>
  <div class="language-switcher">
    <select v-model="currentLanguage" @change="changeLanguage" class="language-select">
      <option v-for="(language, key) in languages" :key="key" :value="key">
        {{ language }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      currentLanguage: localStorage.getItem('language') || this.$i18n.locale, // Výchozí jazyk načteme z localStorage nebo nastavíme výchozí
      languages: {
        en: "en",
        cs: "cs",
      },
    };
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.currentLanguage; // Změní jazyk aplikace
      localStorage.setItem('language', this.currentLanguage); // Uloží aktuální jazyk do localStorage
    },
  },
  mounted() {
    // Načteme jazyk z localStorage, pokud je uložen
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      this.currentLanguage = savedLanguage;
      this.$i18n.locale = savedLanguage;
    }
  }
};
</script>

<style scoped>
.language-switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
}

.language-select {
  background-color: transparent;
  color: white;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  border: none;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 50px;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
  appearance: none;
}

.language-select:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.language-select option {
  color: black;
}

/* Responzivita */
@media (max-width: 768px) {
  .language-switcher {
    justify-content: center;
  }

  .language-select {
    font-size: 0.9rem;
    padding: 0.3rem 0.8rem;
  }
}

@media (max-width: 480px) {
  .language-select {
    font-size: 0.8rem;
    padding: 0.2rem 0.6rem;
  }
}
</style>
