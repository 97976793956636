<template>
  <section class="scroll-section">
    <div class="violin-wrapper" data-aos="fade-up" data-aos-duration="1500">
      <img
          v-for="(violin, index) in violins"
          :key="index"
          :src="violin.src"
          :class="['violin-image', { 'active': index === activeImageIndex }]"
          alt="Violin"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "ScanViolinComponent",
  data() {
    return {
      violins: [
        { src: require("@/assets/img/housle_frontleft2d.png") },
        { src: require("@/assets/img/housle_frontleft2a.png") },
        { src: require("@/assets/img/housle_frontleft2b.png") },
        { src: require("@/assets/img/housle_frontleft2e.png") },
        { src: require("@/assets/img/housle_frontleft2c.png") }
      ],
      activeImageIndex: 0,
    };
  },
  mounted() {
    this.startImageRotation();
  },
  methods: {
    startImageRotation() {
      setInterval(() => {
        this.activeImageIndex = (this.activeImageIndex + 1) % this.violins.length;
      }, 5000);
    },
  },
};
</script>

<style scoped>
.scroll-section {
  position: relative;
  height: 100vh;
  width: 100%;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #06181a;
}

.violin-wrapper {
  position: relative;
  height: 300px;
  width: 300px;
  border-radius: 9999px;
  border: 4px solid rgba(255, 255, 255, 0.10);
  box-shadow: 0 0 0 44px rgba(255, 255, 255, 0.50);
  outline: solid 40px #06181a;
}

.violin-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 5s;
  transform: scale(1.8);
}

.violin-image.active {
  opacity: 1;
}

/* Responzivní úpravy */
@media (max-width: 1200px) {
  .violin-wrapper {
    height: 400px;
    width: 400px;
  }
}

@media (max-width: 768px) {
  .violin-wrapper {
    height: 300px;
    width: 300px;
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.50);
    outline: solid 20px #06181a;
  }

  .violin-image {
    transform: scale(1.2);
  }
}

@media (max-width: 480px) {
  .violin-wrapper {
    height: 250px;
    width: 250px;
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.50);
    outline: solid 10px #06181a;
  }

  .violin-image {
    transform: scale(1.1);
  }
}
</style>
