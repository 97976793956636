<template>
  <section class="obsazeni-section" data-aos="fade-up" data-aos-duration="1000" id="cast">
    <div class="side-text">
      <p>{{ $t('cast') }}</p>
    </div>

    <!-- Seznam osob -->
    <div
        v-for="(person, index) in people"
        :key="index"
        :class="['person align-right', { 'reverse align-left': index % 2 !== 0 }]"
        :data-aos="index % 2 === 0 ? 'fade-right' : 'fade-left'"
        data-aos-duration="1500" data-aos-delay="500"
    >
      <div class="image-container">
        <img :src="person.image" :alt="person.name" />
      </div>
      <div class="content-container" :class="['content', { 'margin-left-imp': index % 2 !== 0 }]">
        <h2>{{ person.name }}</h2>
        <!-- Překlad pomocí $t v šabloně -->
        <p>{{ $t(person.descriptionKey) }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      people: [
        {
          name: 'Václav Pikrt',
          descriptionKey: 'vaclav_pikrt_about',
          image: require('@/assets/img/vaclavpikrt.jpg'),
        },
        {
          name: 'Jan Špidlen',
          descriptionKey: 'jan_spidlen_about',
          image: require('@/assets/img/spidlen.jpg'),
        },
        {
          name: 'Josef Špaček',
          descriptionKey: 'josef_spacek_about',
          image: require('@/assets/img/spacek.jpg'),
        },
        {
          name: 'Eric Blot',
          descriptionKey: 'eric_blot_about',
          image: require('@/assets/img/eric.jpg'),
        },
        {
          name: 'Lukáš Menšík',
          descriptionKey: 'lukas_mensik_about',
          image: require('@/assets/img/lukasmensik.jpg'),
        },
      ],
    };
  },
  mounted() {
    AOS.init({
      once: false,
    });
  },
};
</script>

<style scoped>
.obsazeni-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: white;

}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}


.person {
  display: flex;
  margin-top: 60px;
  width: 100%;
  max-width: 1200px;

}

.person.reverse {
  flex-direction: row-reverse;
  margin-left: -135px;
}

@media (max-width: 1024px)  {
  .person.reverse {
    flex-direction: column;
    margin-left: 0px;
  }
  .person.reverse img {
    margin-left: 0 !important;
  }

}

.person img {
  width: 600px;
  height: auto;
  object-fit: cover;
  margin-right: 2rem;
  max-width: 562px;
}

.person.reverse img {
  margin-right: 0;
  margin-left: 2rem;
}

.content-container {
  max-width: 500px;
  border: 5px dashed #0C2C2F;
  margin-top: 190px;
  margin-left: -110px;
  padding: 50px;
}

.margin-left-imp {
  margin-right: -110px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #b0c7d1;
}

/* Responzivita */
@media (max-width: 1024px) {
  .person {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .person img {
    width: 90%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .content-container {
    margin: 0;
    padding: 1.5rem;
  }

  .margin-left-imp {
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .person img {
    width: 100%;
  }

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .content-container {
    padding: 1rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }
}
</style>
