<template>
  <div v-if="visible" class="modal-overlay" @click="close">
    <div class="modal-content" @click.stop>
      <main class="modal-body">
        <slot></slot>
      </main>
      <footer class="modal-footer">
        <slot name="footer">

        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #0a2224;
  max-width: 700px;
  width: 100%;
  position: relative;
  color: white;
  padding-bottom: 20px;
}



.modal-footer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}


@media (max-width: 768px) {
  .modal-footer {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
    gap: 20px;

  }
}

</style>
