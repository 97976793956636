<template>
  <header class="video-header">
    <!-- Fixní lišta -->
    <!--
    <div class="fixed-bar" @click="openDialog" data-aos="fade-down" data-aos-duration="1500" data-aos-delay="1000">
      <p>{{ $t('premiere_invitation') }}</p>
    </div>
    -->

    <!-- Video Background -->
    <video autoplay loop muted playsinline class="background-video">
      <source :src="videoSource" type="video/mp4" />
      Váš prohlížeč nepodporuje video tag.
    </video>

    <!-- Overlay Content -->
    <div class="header-content">
      <!-- Hamburger ikona -->
      <div class="hamburger" @click="toggleMenu">
        <i v-if="!isMenuOpen" class="pi pi-bars hamburger-icon"></i>
        <i v-else class="pi pi-times close-icon"></i>
      </div>

      <!-- Navigace -->
      <nav :class="{ open: isMenuOpen }" class="navigation">
        <ul>
          <li><a href="#about-film" @click="closeMenu">{{ $t('about') }}</a></li>
          <li><a href="#trailer" @click="closeMenu">{{ $t('trailer') }}</a></li>
          <li><a href="#cast" @click="closeMenu">{{ $t('cast') }}</a></li>
          <li><a href="#crew" @click="closeMenu">{{ $t('crew') }}</a></li>
          <li><a href="#contact" @click="closeMenu">{{ $t('contact') }}</a></li>
          <!--
          <li>
            <button class="button button-success button-menu-center" @click="buyTicket">
              <i class="pi pi-ticket"></i>
              {{ $t('buy_ticket') }}
            </button>
          </li>
          -->
        </ul>
        <!-- Přepínač jazyků -->
        <LanguageSwitcher class="language-switcher" />
      </nav>

      <!-- Titulek stránky -->
      <h1 class="title" data-aos="fade-up" data-aos-duration="1500">{{ $t('title') }}</h1>

      <!-- Skrolovací indikátor -->
      <a href="#about-film" class="scroll-indicator">
        <i class="pi pi-angle-down"></i>
      </a>
    </div>

    <!-- Naše modální okno -->
    <ModalComponent :visible="dialogVisible" title="Premiéra filmu" @close="closeDialog">
      <template v-slot:default>
        <img src="@/assets/img/plakat-event.jpg" alt="Film Poster" class="poster-image" />
      </template>
      <template v-slot:footer>
        <button class="button button-calendar" @click="addToGoogleCalendar">
          <i class="pi pi-calendar"></i>
          Přidat do Google Kalendáře
        </button>
        <button class="button button-success" @click="buyTicket">
          <i class="pi pi-ticket"></i>
          Koupit lístek
        </button>
      </template>
    </ModalComponent>
  </header>
</template>


<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: 'VideoHeaderComponent',
  components: {
    ModalComponent,
    LanguageSwitcher
  },
  data() {
    return {
      isMenuOpen: false,
      dialogVisible: false,
      videoSource: '',
    };
  },
  mounted() {
    this.setVideoSource();
    window.addEventListener('resize', this.setVideoSource);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setVideoSource);
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      document.body.style.overflow = this.isMenuOpen ? 'hidden' : 'auto';
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.style.overflow = 'auto';
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    addToGoogleCalendar() {
      window.open('https://calendar.google.com/calendar/render?action=TEMPLATE&text=Premi%C3%A9ra+filmu+Mistr+housla%C5%99&details=Kinos%C3%A1l+Divadla+A.+Dvo%C5%99%C3%A1ka+-+P%C5%99%C3%ADbram&location=Kinos%C3%A1l+Divadla+A.+Dvo%C5%99%C3%A1ka,+P%C5%99%C3%ADbram&dates=20241108T190000Z/20241108T210000Z\n');
    },
    buyTicket() {
      window.open('https://tickets.colosseum.eu/divadlo-pribram/standard/Hall/Index/3074087/dLvuqvfX9rtUcioOFUzDSw_rov__rov__xcodex_AGA1gFbU4wi5HcXuSCPu-aEIYjtELmtGeLdFYQ__?', '_blank');
    },
    setVideoSource() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        this.videoSource = require('@/assets/video/header_video_mobile.mp4');
      } else {
        this.videoSource = require('@/assets/video/header_video.mp4');
      }
    }
  },
};
</script>


<style scoped>
/* Vaše stávající styly zůstávají stejné */
.video-header {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fixed-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 9px;
  background-color: #0a2224db;
  color: #ffe69c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  transition: all .2s ease-out;
  opacity: 1;
  &:hover {
    color: #cab36f;
  }
}

.fixed-bar p {
  margin: 0;
  font-size: 1.0rem;
  font-weight: 400;
}

/* Stylování dialogu */
.poster-image {
  width: 100%;
}



.header-content {
  position: relative;
  z-index: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 2rem;
}

/* Hamburger ikona */
.hamburger {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
}

.hamburger-icon,
.close-icon {
  font-size: 2rem;
  color: white;
  z-index: 999999;
  position: relative;
  margin-top: 60px;
}

@media (min-width: 1100px) {
  .hamburger {
    display: none; /* Skrytí hamburger ikony */
  }
}

.navigation {
  position: fixed;
  top: 0;
  right: -100%; /* Skryto mimo obrazovku */
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  transition: right 0.4s ease;
}


.navigation.open {
  right: 0;
}

.navigation ul {
  list-style: none;
  gap: 2.5rem;
  margin-top: 80px;
  margin-right: 50px;
}

.navigation li {
  margin: 2rem 0;
}

.navigation a {
  color: #fff;
  text-decoration: none;
  font-size: 1.0rem;
  text-transform: uppercase;
  transition: opacity 0.3s ease-in-out;
}

.navigation a:hover {
  opacity: 0.7;
}


@media (min-width: 1100px) {
  .navigation {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    justify-content: flex-end;
  }

  .navigation ul {
    display: flex;
  }

  .navigation li {
    margin: 0 1rem;
  }

  .button-menu-center {
    margin-top: -20px;
  }

}

/* Styl pro nadpis */
.title {
  font-weight: 400;
  font-size: 4rem;
}

/* Skrolovací indikátor */
.scroll-indicator {
  position: absolute;
  bottom: 120px;
  font-size: 3rem;
  cursor: pointer;
  color: white;
  animation: bounce 3s infinite;
}

@media (max-width: 768px) {
  .scroll-indicator {
    position: absolute;
    bottom: 81px;

  }
}


@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }

}
</style>